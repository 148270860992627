<template>
  <div class="warehouse-double-list-page">
    <el-form ref="dataForm" inline class="bysearchForm">
      <div class="searchLine">
        <el-form-item prop="periodId" class="typeInput" label="时段">
          <el-select
            v-model="dataForm.periodId"
            placeholder="时段"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in periodList"
              :key="index"
              :label="item.withTimeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="orgId" class="typeInput" label="配餐公司">
          <el-select
            v-model="dataForm.orgId"
            placeholder="配餐公司"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in orgList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="companyId" class="typeInput" label="客户公司">
          <el-select
            v-model="dataForm.companyId"
            placeholder="客户公司"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="states" label="状态">
          <el-select
            v-model="dataForm.states"
            placeholder="状态"
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in stateList"
              :key="index"
              :label="item.state"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="typeInput">
          <el-button @click="getDataList()">查询</el-button>
          <el-button
            v-if="isAuth('tc:orgCompanyContract:save')"
            type="primary"
            @click="$dialog('addOrUpdate')"
            >新增</el-button
          >
          <el-button
            v-if="isAuth('tc:orgCompanyContract:delete')"
            type="danger"
            @click="deleteHandle()"
            :disabled="dataListSelections.length <= 0"
            >批量删除</el-button
          >
          <el-button
            type="danger"
            size="mini"
            v-if="isAuth('tc:orgCompanyContract:changeState')"
            :disabled="dataListSelections.length <= 0"
            @click="changeState()"
            >终止合同</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <div class="content-container">
      <div class="divider-border" />
      <div class="profit-loss-detail-container">
        <div class="table-container">
          <el-table
            :data="dataList"
            border
            stripe
            ref="dataList"
            v-loading="dataListLoading"
            @row-click="$clickRow($event, 'dataList')"
            @selection-change="$selectionChange($event, 'dataList')"
            style="width: 100%"
            @select-all="handleSelectAll"
          >
            <el-table-column
              type="selection"
              header-align="center"
              align="center"
              width="50"
            />
            <el-table-column
              prop="orgName"
              header-align="center"
              align="center"
              label="供应商名称"
            />
            <el-table-column
              prop="companyName"
              header-align="center"
              align="center"
              label="公司"
            />
            <el-table-column
              prop="periodNames"
              header-align="center"
              align="center"
              label="时段"
            />

            <el-table-column
              prop="startDate"
              header-align="center"
              align="center"
              label="生效日期"
            />
            <el-table-column
              prop="endDate"
              header-align="center"
              align="center"
              label="终止日期"
            />

            <el-table-column
              prop="state"
              header-align="center"
              align="center"
              width="120"
              label="状态"
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.state === 0" size="small"
                  >未生效</el-tag
                >
                <el-tag v-else-if="scope.row.state === 1" size="small"
                  >生效中</el-tag
                >
                <el-tag v-else-if="scope.row.state === 2" size="small"
                  >已失效</el-tag
                >
                <el-tag v-else-if="scope.row.state === 3" size="small"
                  >已终止</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="createdAt"
              header-align="center"
              align="center"
              label="创建日期"
            />

            <el-table-column
              prop="createdBy"
              header-align="center"
              align="center"
              label="创建者"
            />
            <el-table-column
              header-align="center"
              align="center"
              width="280"
              label="操作"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="isAuth('tc:orgCompanyContract:info')"
                  type="text"
                  size="small"
                  @click="$dialog('addOrUpdate', scope.row.id, true)"
                  >查看</el-button
                >
                <el-button
                  v-if="isAuth('tc:orgCompanyContract:update')"
                  type="text"
                  size="small"
                  @click="
                    $dialog(
                      'addOrUpdate',
                      scope.row.id,
                      false,
                      scope.row.state === 1,
                    )
                  "
                  >修改</el-button
                >
                <el-button
                  v-if="isAuth('tc:orgCompanyContract:delete')"
                  type="text"
                  size="small"
                  @click="deleteHandle(scope.row.id)"
                  >删除</el-button
                >
                <el-button
                  v-if="isAuth('tc:orgCompanyContractLog:list')"
                  type="text"
                  size="small"
                  @click="$dialog('companyContractLog', scope.row.id)"
                  >查看日期记录</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination-container">
          <el-pagination
            background
            @size-change="$sizeChange($event, 'dataList')"
            @current-change="$currentChange($event, 'dataList')"
            :current-page="dataListIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="dataListSize"
            :total="dataListCount"
            layout="total, sizes, prev, pager, next, jumper"
          />
        </div>
      </div>
    </div>

    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @success="getDataList"
    />
    <!-- 弹窗, 新增 / 修改 -->
    <company-contract-log
      v-if="companyContractLogVisible"
      ref="companyContractLog"
    />
  </div>
</template>

<script>
import { threeListMixin, listMixin, normal } from '@/mixins';
import AddOrUpdate from './company-contract-add-or-update';
import companyContractLog from './company-contract-log';
import { getCompanyList, getPeriodList, getOrgList } from '@/utils/options.js';
export default {
  mixins: [threeListMixin, listMixin, normal],
  data() {
    return {
      dataForm: {
        orgId: '',
        companyId: '',
        periodId: '',
        states: [0, 1],
      },
      ids: '',
      firstData: '',
      periodTable: '',
      orgIds: [],
      chooseIndex: 0,
      dataList: [],
      addOrUpdateVisible: false,
      companyContractLogVisible: false,
      dataListSelections: [],
      companyList: [],
      orgList: [],
      periodList: [],
      stateList: [
        { id: 0, state: '未生效' },
        { id: 1, state: '生效中' },
        { id: 2, state: '已失效' },
        { id: 3, state: '已终止' },
      ],
    };
  },
  components: {
    AddOrUpdate,
    companyContractLog,
  },
  activated() {
    this.getDataList();
  },
  created() {
    this.getPeriodList();
    this.getOrgList();
    this.getCompanyList();
  },
  methods: {
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },

    getCompanyList() {
      getCompanyList().then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },
    changeState(id) {
      let ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      this.$handleDelete({
        tip: `是否终止这些合同?`,
        url: '/tc/orgCompanyContract/changeState',
        data: ids,
      });
    },
    // 获取数据列表
    getDataList() {
      this.$api({
        url: '/tc/orgCompanyContract/list',
        params: {
          page: this.dataListIndex,
          limit: this.dataListSize,
          orgId: this.dataForm.orgId,
          companyId: this.dataForm.companyId,
          periodId: this.dataForm.periodId,
          states: this.dataForm.states.join(','),
        },
        after: (data) => {
          if (data && data.code === 0) {
            this.dataList = data.page.records;
            this.dataListCount = data.page.total;
          } else {
            this.dataList = [];
            this.dataListCount = 0;
          }
        },
      });
    },
    // 删除
    deleteHandle(id) {
      let ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      this.$handleDelete({
        tip: `是否确认对这些公司进行删除操作?`,
        url: '/tc/orgCompanyContract/delete',
        data: ids,
      });
    },
    getPeriodList() {
      getPeriodList(1).then(({ data }) => {
        if (data && data.status === 0) {
          this.periodList = data.data.items;
        }
      });
    },
  },
};
</script>
